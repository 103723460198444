.pro-invoice {
    position: relative;
    width: 210mm;
    min-height: 95vh;
    margin: auto;
    padding: 5mm 10mm 73px 15mm;
    &-top {
        img {
            max-width: 300px;
            height: 33px;
        }
    }
    &-signature {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 73px;
    }
    .btn {
        @media print {
                display: none;
            }
    }
}

.category {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    &-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        padding: 10px;
    }

    &-img {
        width: 100px;
        height: 100px;
        padding: 15px;
        background-color: transparent;
        border: 1px solid black;
        border-radius: 50%;
        &:hover {
            transform: scale(1.1);
            border: none;
            background-color: red;
        }

        a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: inherit;
        }
    }

    &-text{
        margin-top: 10px;
        h6 {
            margin: 0;
            &:hover {
                    transform: scale(1.1);
                }
        }
        a{
            text-decoration: none;
            color: inherit;
            &:hover {
                    color: red;
                }
        }
        .p-holder{
            height: 75px;
        }
    }
}
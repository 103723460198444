@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* font-family: 'Poppins', sans-serif; */
/*   random images:      https://source.unsplash.com/random    */
/**********************************************
                Global styles
**********************************************/
*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #ffffff;
    --silver: #cccccc;
    --grey: #333333;
    --black: #000000;
    --blue: #0d6efd;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #d63384;
    --red: #dc3545;
    --brown: #663300;
    --orange: #fd7e14;
    --gold: #ff9900;
    --yellow: #ffc107;
    --green: #198754;
    --teal: #20c997;
    --cyan: #0dcaf0;
    --font-family: 'Poppins', sans-serif;
    --font-size: 1rem;
    --font-weight: 400;
    --line-height: 1.5;
    --body-color: var(--black);
    --body-bg: var(--white);
    --gutter-x: 1.5rem;
    --gutter-y: 0;
    --breakpoint-576: 576px;
    --breakpoint-768: 768px;
    --breakpoint-992: 992px;
    --breakpoint-1200: 1200px;
    --breakpoint-1400: 1400px;
}

a {
    text-decoration: none;
    color: inherit;
}

ul li {
    list-style: none;
}

body {
    position: relative;
    min-height: 100vh;
    padding-bottom: 50px;
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    color: var(--black);
    background-color: var(--white);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    scroll-behavior: smooth;
}

/**********************************************
                    Layout
**********************************************/

/**********************************************
                Specific styles
**********************************************/


/* Footer */
.my-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    text-align: center;
    color: var(--body-color);
    background-color: var(--body-bg);
    a {
        text-decoration: none;
        color: inherit;
    }

    span {
        text-shadow: 1px 1px 1px var(--red);

        &:hover {
            color: var(--green);
            text-shadow: 1px 1px 1px var(--green);
        }
    }
}

/**********************************************
                Helper classes
**********************************************/
.list-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}

.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

/*********************************************
               @media Queries
*********************************************/
@media print {
    .print-hide {
        display: none !important;
    }
}

/* @media all and (min-width: var(--breakpoint-576)) {} */

@import 'assets/scss/loader.scss';
@import 'assets/scss/login.scss';
@import 'assets/scss/noticeCookie.scss';
@import 'assets/scss/error.scss';
@import 'assets/scss/category.scss';
@import 'assets/scss/categoryChildren.scss';
@import 'assets/scss/categoryMainChild.scss';
@import 'assets/scss/passShowHide.scss';
@import 'assets/scss/image-carousel.scss';
@import 'assets/scss/articleByCategory.scss';
@import 'assets/scss/admin.scss';
@import 'assets/scss/modal.scss';
@import 'assets/scss/articleCategoryParentPage.scss';
@import 'assets/scss/pagination.scss';
@import 'assets/scss/proInvoice.scss';
@import 'assets/scss/paymentLogo.scss';
@import 'assets/scss/paymentBank.scss';
@import 'assets/scss/gallery.scss';
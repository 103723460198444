.cookie-notice-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 50px;
    width: 400px;
    padding: 10px;
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, .9);
    z-index: 3;

    a {
        text-decoration: none;
    }
}
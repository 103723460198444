.category-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &-box {
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: center;
        gap: 5px;
        max-width: 250px;
        height: 310px;
        padding: 10px;
    }

    &-img {
        width: 200px;
        height: 200px;
        padding: 3px;
        border: 1px solid silver;
        border-radius: 10px;
        overflow: hidden;

        &:hover {
            transform: scale(1.05);
            border-color: red;
        }

        a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: inherit;
        }
    }

    &-text {
        margin-bottom: 30px;

        h6 {
            margin: 0;

            &:hover {
                transform: scale(1.1);
            }

            a {
                display: block;
                text-decoration: none;
                color: inherit;

                &:hover {
                    color: red;
                }
            }
        }
        p {
            margin: 0;
            padding: 0;
        }

        .p-holder {
            margin-top: 5px;
            li {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }
    }
}
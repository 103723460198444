.modal {
    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, .8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-content {
        max-width: 600px;
        min-height: 300px;
        padding: 0 20px;

        .close {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
        }
    }
}
.header {
    &-top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 0;
        gap: 20px;
        width: 100%;
        height: 30px;

        .icons {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            color: red;

            &:hover {
                transform: scale(1.3);
            }
        }
        &-cart {
            position: relative;
            &-num {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 8px;
                right: -15px;
                width: 20px;
                height: 20px;
                color: red;
            }
        }
    }

    &-line {
        border: 0;
        height: 3px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(255, 2, 2), rgba(0, 0, 0, 0));
    }

    &-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;

        &-logo {
            width: 360px;
            height: 35px;
            background: url(../img/logo.jpg) center/cover no-repeat;
            &:hover {
                    transform: scale(1.01);
                }
        }
    }
}
.slider-img {
    width: 100%;
    height: 500px;
    object-fit: cover
}
.alice-carousel__prev-btn {
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    width: auto !important;
    text-align: left !important;
    background-color: rgba($color: #ffffff, $alpha: .3);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &:hover {
        background-color: rgba($color: #ffffff, $alpha: .8);
    }
}

.alice-carousel__next-btn {
    position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: auto !important;
        text-align: right !important;
        background-color: rgba($color: #ffffff, $alpha: .3);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    
        &:hover {
            background-color: rgba($color: #ffffff, $alpha: .8);
        }
}
.article {
    .article-back-btn {
        position: absolute;
        top: 0px;
        right: 10px;
    }
    .table {
        td {
            font-size: .9rem;

            a {
                width: 100%;
                height: 35px;
                margin: 0;
                padding: 0;

                h4 {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}
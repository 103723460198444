* {
    transition: all 0.6s;
}

html {
    height: 100%;
}

body {
    font-family: 'Lato', sans-serif;
    color: #888;
    margin: 0;
}

#main {
    display: table;
    width: 100%;
    height: 90vh;
    text-align: center;

    .fof {
        display: table-cell;
        vertical-align: middle;

        h1 {
            font-size: 36px;
            display: inline-block;
            padding-right: 12px;
            animation: type .5s alternate infinite;
        }

        p {
            color: #888;
        }

        a {
            display: block;
            text-decoration: none;
            img {
                width: 90%;
                max-width: 500px;
            }
        }
    }
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #888;
    }

    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}
.admin {
    &-img {
        max-width: 360px;
        height: auto;
        margin: auto;
        padding: 16px 0;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &-holder {
        height: 70vh;
        justify-content: center;
    }
}

.users {
    .table {
        td {
            a {
                display: block;
                width: 50px;
                text-align: center;
            }
        }
    }
}
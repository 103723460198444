.pagination-holder {
    .pagination {
        .page-link {
            color: red !important;
            cursor: pointer;
        }
    }

    .active>.page-link,
    .page-link.active {
        color: white !important;
        background-color: red !important;
        border-color: red !important;
    }

    .dropdown-menu {
        min-width: 73px !important;
        background-color: transparent;
        border: none;

        p {
            text-align: center;
            color: white;
            background-color: rgba($color: #6c757d, $alpha: 1);
            cursor: pointer;
            border: 1px solid silver;
            border-radius: .25rem;
        }
    }
}